<template>
    <ng-dialog class="coupon-rule-dialog" :visible="show" pcWidth='560px' :maskClosable="false" :footer="null"
        @close="onClose">
        <div class="con">
            <div class="subscrib-content">
                <div class="cont-header">
                    <!-- <span v-if="vipType==2" @click="onClickAuth(2)">个人会员</span>
                    <span v-if="vipType==1" @click="onClickAuth(1)">企业会员</span> -->
                </div>
                <a-form :form="form" class="cont" v-if="auth_type==2"  @submit="handleSubmit" >
                    <div style="margin-top:22px;">
                        <div class="title">添加授权主体</div>
                        <div class="line"></div>
                        <div class="desc">以下内容是正版授权书的关键信息，一旦提交不支持修改，请务必准确填写</div>
                        <a-form-item class="cell1">
                            <div class="cell-name">授权主体</div>
                            <a-input placeholder="请输入真实姓名" class="input-c"
                                v-decorator="['name', { rules: [{ required: true, message: '请输入真实姓名!' }] }]" />
                        </a-form-item>
                        <a-form-item class="cell1">
                            <div class="cell-name">联系方式</div>
                            <a-input placeholder="请输入手机号" class="input-c"
                                v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]" />
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" html-type="submit" class="button-c">确认提交</a-button>
                        </a-form-item>
                    </div>
                </a-form>

                <a-form :form="form_company"  class="cont" v-if="auth_type==1"  @submit="handleSubmitCompany" >
                    <div style="margin-top:22px;">
                        <div class="title">添加授权主体</div>
                        <div class="line"></div>
                        <div class="desc">完成认证后即可下载高清音乐，认证信息将用于生成电子版授权书</div>
                        <a-form-item class="cell1">
                            <div class="cell-name">授权主体</div>
                            <a-input placeholder="请输入企业全称" class="input-c"
                                v-decorator="['name', { rules: [{ required: true, message: '请输入企业全称!' }] }]" />
                        </a-form-item>
                        <a-form-item class="cell1">
                            <div class="cell-name">税号</div>
                            <a-input placeholder="纳税人唯一识别号" class="input-c"
                                v-decorator="['due', { rules: [{ required: true, message: '请输入纳税人唯一识别号!' }] }]" />
                        </a-form-item>
                        <a-form-item class="cell1">
                            <div class="cell-name">联系方式</div>
                            <a-input placeholder="请输入手机号" class="input-c"
                                v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]" />
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" html-type="submit" class="button-c">确认提交</a-button>
                        </a-form-item>
                    </div>
                </a-form>
            </div>
        </div>
    </ng-dialog>
</template>

<script>
    import NgDialog from '@/components/NgDialog.vue'
    import Login from "@/pages/components/login/Login.vue";
    import FindPwd from "@/pages/components/login/FindPwd.vue";
    import Register from "@/pages/components/login/Register.vue";

    import { dialogMixins } from "@/mixins/index";
    import { mapMutations } from "vuex";

    export default {
        data() {
            return {
                vipType: 2,
                form: this.$form.createForm(this, "auth-personal"),
                form_company: this.$form.createForm(this, "auth-company"),
                companyData: [],
                LoadcompanyData: false,
                personalData: [],
                LoadpersonalData: false,
                show: true,
            }
        },
        components: {
            Login,
            Register,
            FindPwd,
            NgDialog,
        },
        props: {
            order_id: String,
            auth_type: Number,
        },
        created() {
            this.$axios.vipAuthlist().then(res => {
                if (res.data.code == 0) {
                    let auth_data = res.data.data.list;
                    if (auth_data[2]) {
                        this.personalData = auth_data[2];
                    } else {
                        this.LoadpersonalData = true;
                    }
                    if (auth_data[1]) {
                        this.companyData = auth_data[1];
                    } else {
                        this.LoadcompanyData = true;
                    }
                }
            })
        },
        methods: {
            onClose() {
                this.$emit('close');
            },
            onClickAuth(idx) {
                this.vipType = idx;
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    console.log(values.name,values.phone)
                    if (!err) {
                        let params = {
                            'auth_type': 2,
                            'order_id' :this.order_id,
                            'name': values.name,
                            'phone': values.phone
                        }
                        let that = this;
                        this.$axios.saveAuthV2(params).then(res => {
                            const data = res.data;
                            if (data && data.code == 0) {
                                that.LoadpersonalData = false;
                                that.personalData.name = data.data.name;
                                that.personalData.phone = data.data.phone;
                                // this.$router.push({path:'/member_v2?menukey=4'});
                                this.$emit('close');
                            }
                        }).catch(() => {
                            that.LoadpersonalData = true;
                        })
                    }
                });
            },
            handleSubmitCompany(e) {
                e.preventDefault();
                this.form_company.validateFields((err, values) => {
                    if (!err) {
                        let params = {
                            'auth_type': 1,
                            'order_id' :this.order_id,
                            'name': values.name,
                            'phone': values.phone,
                            'due': values.due
                        }
                        let that = this;
                        this.$axios.saveAuthV2(params).then(res => {
                            const data = res.data;
                            if (data && data.code == 0) {
                                that.LoadcompanyData = false;
                                that.companyData.name = data.data.name;
                                that.companyData.phone = data.data.phone;
                                that.companyData.due = data.data.due;
                                // this.$router.push({path:'/member_v2?menukey=4'});
                                this.$emit('close');
                            }
                        }).catch(() => {
                            that.LoadcompanyData = true;
                        })
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
    .coupon-rule-dialog {
        .cont-header {
            position: absolute;
            top: -60px;
            left: 0;
            color: #333333;
            font-weight: 500;
            font-size: 22px;
            width: 100%;
            text-align: center;
        }

        .ant-modal {
            width: 560px;
            /* height: 350px; */
            /* top: 10%; */
            padding-top: 8%; 
        }

        .ant-modal-content {
            border-radius: 6px;

            .ant-modal-close {
                border-radius: 6px;
                padding-top: 18px;
            }
        }

        .ant-modal-body {
            padding: 1px 1px !important;

            .header-con {
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                padding-bottom: 16px;

                .header {
                    font-size: 28px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.85);
                }

                span {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.85);
                    margin-top: 8px;
                }
            }

            .con {
                margin-top: 14px;

                .rule-tit {
                    margin-bottom: 16px;
                    font-size: 18px;
                }

                .code-label {
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.85);
                }
            }

            .btn-wrap {
                // margin-top: 32px;
            }
        }

        .ant-drawer-body-inner {
            padding-top: 60px !important;
        }

        .logon-tabs-wrap {
            .ant-tabs-bar {
                margin-bottom: 64px;
            }

            .ant-tabs-tab {
                font-size: 18px !important;
                color: rgba(0, 0, 0, 0.55);
            }

            .ant-tabs-tab-active {
                font-size: 24px !important;
                color: rgba(0, 0, 0, 0.85);
            }

            .ant-tabs-ink-bar {
                background-color: rgba(0, 0, 0, 0.85) !important;
            }
        }
        .ant-form-explain {
            margin-left: 120px;
            margin-top: 40px;
        }
        .input-c {
            width: 400px;
            position: absolute;
            left: 116px;
            height: 40px;
            border: solid 1px #E0E0E0;
            background-color: #FFFFFF !important;
        }

        .title {
            margin-left: 24px;
            color: #333333;
            font-size: 18px;
            font-weight: 500;
        }

        .line {
            border-bottom: 1px solid #E6E6E6;
            margin-top: 16px;
        }

        .desc {
            margin-left: 24px;
            color: #999999;
            font-size: 14px;
            font-weight: 300;
            margin-top: 14px;
        }

        .cell1 {
            margin-top: 24px;
            display: block;
            height: 40px;
        }

        .cell-name {
            left: 24px;
            position: absolute;
            line-height: 40px;
            width: 80px;
        }

        .button-c {
            width: 120px;
            height: 44px;
            background: linear-gradient(180deg, #FF992B 0%, #FF7B13 100%);
            border-radius: 4px;
            left: 220px;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }
</style>